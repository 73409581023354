import React, { useState, useCallback, useEffect } from 'react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Listbox } from '@headlessui/react';
import { BillingType, IndividualBilling, CompanyBilling, BillingInfo } from '../../../types/order';
import { Order } from '../../../services/orderService';

interface BillingSectionProps {
  updateOrderData: (data: Partial<Order>) => void;
  extraCharactersCount: number;
  initialData?: BillingInfo;
}

interface ExtendedBillingInfo extends BillingInfo {
  extraFees: { [key: number]: number };
}

const BillingSection: React.FC<BillingSectionProps> = ({ updateOrderData, extraCharactersCount, initialData }) => {
  const [billingInfo, setBillingInfo] = useState<ExtendedBillingInfo>({
    totalAmount: initialData?.totalAmount || 0,
    modelFee: initialData?.modelFee || 0,
    driverFee: initialData?.driverFee || 0,
    billingType: initialData?.billingType || 'individual',
    individualBilling: initialData?.individualBilling || {},
    companyBilling: initialData?.companyBilling || {},
    extraFees: initialData?.extraFees || {},
  });  

  useEffect(() => {
    // Asigurăm-ne că avem întotdeauna numărul corect de extraFees
    const updatedExtraFees = { ...billingInfo.extraFees };
    for (let i = 0; i < extraCharactersCount; i++) {
      if (!(i in updatedExtraFees)) {
        updatedExtraFees[i] = 0;
      }
    }
    setBillingInfo(prev => ({ ...prev, extraFees: updatedExtraFees }));
  }, [extraCharactersCount]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setBillingInfo(prev => {
      const newState = { ...prev };
      
      if (name.includes('.')) {
        const [section, field] = name.split('.');
        if (section === 'individualBilling' || section === 'companyBilling') {
          newState[section] = {
            ...newState[section],
            [field]: type === 'checkbox' ? checked : value,
          };
        }
      } else if (name.startsWith('extraModelFee')) {
        const index = parseInt(name.split('_')[1]);
        newState.extraFees = { ...newState.extraFees, [index]: parseFloat(value) || 0 };
      } else {
        if (name === 'totalAmount' || name === 'modelFee' || name === 'driverFee') {
          newState[name] = parseFloat(value) || 0;
        } else if (name === 'billingType') {
          newState.billingType = value as BillingType;
        }
      }
  
      return newState;
    });
  }, []);

  useEffect(() => {
    console.log('BillingInfo updated:', billingInfo); // Log the current state of billingInfo
    updateOrderData({ billingInfo: billingInfo });
  }, [billingInfo, updateOrderData]);  

  const renderInput = (label: string, name: string, value: string | number, type: string = 'text') => (
    <div className="sm:col-span-1">
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900 mb-2">
        {label}
      </label>
      <div className="mt-2">
        <input
          type={type}
          id={name}
          name={name}
          value={value.toString()}
          onChange={handleInputChange}
          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );

  return (
    <div className="space-y-6 sm:space-y-5 border-t border-gray-200 pt-6">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">Informații facturare</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Completați detaliile de facturare pentru această comandă.
        </p>
      </div>

      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
        {renderInput('Sumă totală', 'totalAmount', billingInfo.totalAmount, 'number')}
        {renderInput('Tarif model', 'modelFee', billingInfo.modelFee, 'number')}
        
        {[...Array(extraCharactersCount)].map((_, index) => 
          renderInput(`Tarif model extra ${index + 1}`, `extraModelFee_${index}`, billingInfo.extraFees[index] || 0, 'number')
        )}
        
        {renderInput('Tarif șofer', 'driverFee', billingInfo.driverFee, 'number')}
      </div>

      <div className="sm:col-span-3">
        <label htmlFor="billingType" className="block text-sm font-medium leading-6 text-gray-900">
          Tip facturare
        </label>
        <Listbox
          value={billingInfo.billingType}
          onChange={(value: BillingType) => setBillingInfo(prev => ({ ...prev, billingType: value }))}
        >
          <div className="mt-1 relative">
            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="block truncate">
                {billingInfo.billingType === 'individual' ? 'Persoană fizică' : 'Persoană juridică'}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              <Listbox.Option
                value="individual"
                className={({ active }) =>
                  `${active ? 'text-white bg-indigo-600' : 'text-gray-900'} cursor-default select-none relative py-2 pl-3 pr-9`
                }
              >
                {({ selected }) => (
                  <>
                    <span className="block truncate">Persoană fizică</span>
                  </>
                )}
              </Listbox.Option>
              <Listbox.Option
                value="company"
                className={({ active }) =>
                  `${active ? 'text-white bg-indigo-600' : 'text-gray-900'} cursor-default select-none relative py-2 pl-3 pr-9`
                }
              >
                {({ selected }) => (
                  <>
                    <span className="block truncate">Persoană juridică</span>
                  </>
                )}
              </Listbox.Option>
            </Listbox.Options>
          </div>
        </Listbox>
      </div>

      {billingInfo.billingType === 'individual' && (
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
          {renderInput('Nume client', 'individualBilling.clientName', billingInfo.individualBilling?.clientName || '')}
          {renderInput('CNP', 'individualBilling.cnp', billingInfo.individualBilling?.cnp || '')}
          {renderInput('Județ', 'individualBilling.county', billingInfo.individualBilling?.county || '')}
          {renderInput('Oraș', 'individualBilling.city', billingInfo.individualBilling?.city || '')}
          {renderInput('Stradă', 'individualBilling.street', billingInfo.individualBilling?.street || '')}
          {renderInput('Instituție', 'individualBilling.institution', billingInfo.individualBilling?.institution || '')}
        </div>
      )}

      {billingInfo.billingType === 'company' && (
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
          {renderInput('Nume companie', 'companyBilling.companyName', billingInfo.companyBilling?.companyName || '')}
          {renderInput('Cod fiscal', 'companyBilling.fiscalCode', billingInfo.companyBilling?.fiscalCode || '')}
          {renderInput('Număr Reg. Com.', 'companyBilling.regCom', billingInfo.companyBilling?.regCom || '')}
          <div className="sm:col-span-3">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="companyBilling.isVatPayer"
                  name="companyBilling.isVatPayer"
                  type="checkbox"
                  checked={billingInfo.companyBilling?.isVatPayer || false}
                  onChange={handleInputChange}
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="companyBilling.isVatPayer" className="font-medium text-gray-700">
                  Plătitor de TVA
                </label>
              </div>
            </div>
          </div>
          {renderInput('Județ', 'companyBilling.county', billingInfo.companyBilling?.county || '')}
          {renderInput('Oraș', 'companyBilling.city', billingInfo.companyBilling?.city || '')}
          {renderInput('Adresă', 'companyBilling.address', billingInfo.companyBilling?.address || '')}
          {renderInput('Bancă', 'companyBilling.bank', billingInfo.companyBilling?.bank || '')}
          {renderInput('IBAN', 'companyBilling.iban', billingInfo.companyBilling?.iban || '')}
          {renderInput('Reprezentant legal', 'companyBilling.legalRep', billingInfo.companyBilling?.legalRep || '')}
          {renderInput('Funcție reprezentant legal', 'companyBilling.legalRepFunction', billingInfo.companyBilling?.legalRepFunction || '')}
          {renderInput('Instituție', 'companyBilling.institution', billingInfo.companyBilling?.institution || '')}
        </div>
      )}
    </div>
  );
};

export default BillingSection;
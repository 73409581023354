import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getOrderById, deleteOrder, Order } from '../../services/orderService';
import { useNotification } from '../components/Notification';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import ConfirmDialog from '../../shared/ConfirmDialog';

const OrderView: React.FC = () => {
  const { orderSlug } = useParams<{ orderSlug: string }>();
  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { addNotification } = useNotification();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchOrder = async () => {
      if (orderSlug) {
        const idMatch = orderSlug.match(/-([a-f0-9]+)$/);
        const orderId = idMatch ? idMatch[1] : null;
        
        if (orderId) {
          try {
            const fetchedOrder = await getOrderById(orderId);
            setOrder(fetchedOrder);
          } catch (error) {
            console.error('Eroare la obținerea detaliilor comenzii:', error);
            addNotification('error', 'Eroare', 'Nu s-au putut încărca detaliile comenzii.');
            navigate('/dashboard/comenzi', { replace: true });
          }
        } else {
          console.error('ID-ul comenzii nu a putut fi extras din URL');
          addNotification('error', 'Eroare', 'ID-ul comenzii este invalid.');
          navigate('/dashboard/comenzi', { replace: true });
        }
      }
      setLoading(false);
    };

    fetchOrder();
  }, [orderSlug, addNotification, navigate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDelete = async () => {
    if (order?._id) {
      try {
        await deleteOrder(order._id);
        addNotification('success', 'Succes', 'Comanda a fost ștearsă cu succes.');
        navigate('/dashboard/comenzi', { replace: true });
      } catch (error) {
        console.error('Eroare la ștergerea comenzii:', error);
        addNotification('error', 'Eroare', 'Nu s-a putut șterge comanda.');
      }
    }
  };

  if (loading) return <div>Se încarcă...</div>;
  if (!order) return <div>Comanda nu a fost găsită.</div>;

  return (
    <div>
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="sm:flex sm:items-center">
          <Link to="/dashboard/comenzi" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
            ← Înapoi la lista de comenzi
          </Link>
        </div>
        <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0">
          <div className="flex items-center space-x-3">
            <button onClick={() => {/* Navigare la comanda anterioară */}} className="inline-flex items-center rounded-full border border-gray-300 bg-white p-1 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button onClick={() => {/* Navigare la comanda următoare */}} className="inline-flex items-center rounded-full border border-gray-300 bg-white p-1 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="relative ml-3" ref={dropdownRef}>
            <button
              type="button"
              onClick={() => setShowDropdown(!showDropdown)}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Acțiuni
            </button>
            {showDropdown && (
              <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <a href="#" className="block px-4 py-2 text-sm text-gray-700" onClick={() => setShowDeleteConfirm(true)}>Ștergere</a>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-6 border-t border-gray-100 pt-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">ID Comandă</dt>
            <dd className="mt-1 flex items-center text-sm text-gray-900">
              <span>{order.shortId}</span>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Model</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {`${order.character.firstName} ${order.character.lastName}`}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Data</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {order.period.startDate}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Ora de început</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {order.period.startTime}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Ora de sfârșit</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {order.period.endTime}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Adresă</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {order.address}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Zonă</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {order.zone}
            </dd>
          </div>
        </dl>
      </div>

      <div className="mt-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Editarea comenzilor este temporar indisponibilă
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                Ne cerem scuze, dar în acest moment lucrăm la îmbunătățirea funcționalității de editare a comenzilor. 
                Această opțiune va fi disponibilă în curând. Între timp, puteți în continuare să vizualizați detaliile comenzii și să o ștergeți dacă este necesar.
              </p>
            </div>
          </div>
        </div>
      </div>

      <ConfirmDialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleDelete}
        title="Confirmare ștergere comandă"
        description="Sunteți sigur că doriți să ștergeți această comandă? Această acțiune nu poate fi anulată."
        confirmText="Șterge"
        cancelText="Anulează"
      />
    </div>
  );
};

export default OrderView;
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import {
  XMarkIcon,
  HomeIcon,
  UsersIcon,
  CurrencyDollarIcon,
  UserGroupIcon,
  TruckIcon,
  ListBulletIcon,
  ChevronDownIcon,
  XCircleIcon,
  ShoppingBagIcon,
  ChartBarIcon,
  ClockIcon
} from '@heroicons/react/24/outline';
import logo from '../../assets/images/inchiriazamoscraciun.png';
import { version } from '../../version';

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
}

type IconType = React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement> & {
  title?: string | undefined;
  titleId?: string | undefined;
}>;

interface NavigationItemBase {
  name: string;
  href: string;
  icon: IconType;
  disabled?: boolean;
}

interface NavigationItemWithSubmenu extends NavigationItemBase {
  submenu: NavigationItemBase[];
}

type NavigationItem = NavigationItemBase | NavigationItemWithSubmenu;

interface NavigationSection {
  category: string;
  items: NavigationItem[];
}

const navigation: NavigationSection[] = [
  { 
    category: 'Gestionare',
    items: [
      { name: 'Dashboard', href: '/dashboard', icon: HomeIcon },
      { name: 'Modele', href: '/dashboard/modele', icon: UsersIcon },
      { name: 'Clienți', href: '/dashboard/clienti', icon: UserGroupIcon },
      { name: 'Șoferi', href: '/dashboard/soferi', icon: TruckIcon },
    ]
  },
  {
    category: 'Financiar',
    items: [
      { 
        name: 'Comenzi', 
        href: '/comenzi', 
        icon: CurrencyDollarIcon,
        submenu: [
          { name: 'Lista Comenzi', href: '/dashboard/comenzi', icon: ListBulletIcon },
          { name: 'Comanda Nouă', href: '/dashboard/comenzi/comanda-noua', icon: ShoppingBagIcon },
          { name: 'Comenzi Anulate', href: '/dashboard/comenzi/comenzi-anulate', icon: XCircleIcon, disabled: true }, // Dezactivat
          { name: 'Comenzi Arhivate', href: '/dashboard/comenzi/comenzi-arhivate', icon: ClockIcon, disabled: true }, // Dezactivat
        ]
      },
    ]
  },
  {
    category: 'Administrare',
    items: [
      { name: 'Utilizatori', href: '/dashboard/utilizatori', icon: UsersIcon },
      { name: 'Optimizare', href: '/dashboard/optimizare', icon: ChartBarIcon, disabled: true },
      { name: 'Activitate', href: '/dashboard/activitate', icon: ClockIcon, disabled: true },
    ]
  },
];

const Sidebar: React.FC<SidebarProps> = ({ sidebarOpen, setSidebarOpen }) => {
  const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);
  const location = useLocation();

  const toggleSubmenu = (itemName: string) => {
    setOpenSubmenu(openSubmenu === itemName ? null : itemName);
  };

  const hasSubmenu = (item: NavigationItem): item is NavigationItemWithSubmenu => {
    return 'submenu' in item;
  };

  const isSubmenuActive = (item: NavigationItemWithSubmenu) => {
    return item.submenu.some(subItem => location.pathname === subItem.href);
  };

  const renderNavigation = () => (
    <ul role="list" className="space-y-6">
      {navigation.map((section) => (
        <li key={section.category}>
          <div className="text-xs font-semibold leading-6 text-white">{section.category}</div>
          <ul role="list" className="-mx-2 mt-2 space-y-1">
            {section.items.map((item) => (
              <li key={item.name}>
                {hasSubmenu(item) ? (
                  <div>
                    <button
                      onClick={() => toggleSubmenu(item.name)}
                      className={`flex w-full items-center justify-between rounded-md p-2 text-sm leading-6 font-semibold ${
                        isSubmenuActive(item) ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800'
                      }`}
                    >
                      <div className="flex items-center">
                        <item.icon className="h-6 w-6 shrink-0 mr-3" aria-hidden="true" />
                        {item.name}
                      </div>
                      <span className="ml-auto">
                        <ChevronDownIcon
                          className={`h-5 w-5 transition-transform ${
                            openSubmenu === item.name ? 'rotate-180' : ''
                          }`}
                          aria-hidden="true"
                        />
                      </span>
                    </button>
                    {openSubmenu === item.name && (
                      <ul className="mt-1 pl-4">
                        {item.submenu.map((subItem) => (
                          <li key={subItem.name}>
                            <NavLink
                              to={subItem.href}
                              className={({ isActive }) =>
                                `group flex items-center rounded-md p-2 text-sm leading-6 font-semibold ${
                                  isActive
                                    ? 'bg-gray-800 text-white'
                                    : subItem.disabled
                                    ? 'text-gray-500 cursor-not-allowed'
                                    : 'text-gray-400 hover:text-white hover:bg-gray-800'
                                }`
                              }
                              onClick={(e) => subItem.disabled && e.preventDefault()}
                              end
                            >
                              <subItem.icon className="h-5 w-5 shrink-0 mr-3" aria-hidden="true" />
                              {subItem.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ) : (
                  <NavLink
                    to={item.href}
                    end={item.href === '/dashboard'}
                    className={({ isActive }) =>
                      `group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold ${
                        isActive
                          ? 'bg-gray-800 text-white'
                          : item.disabled
                          ? 'text-gray-500 cursor-not-allowed'
                          : 'text-gray-400 hover:text-white hover:bg-gray-800'
                      }`
                    }
                    onClick={(e) => item.disabled && e.preventDefault()}
                  >
                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    {item.name}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );

  const renderSidebarContent = () => (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
      <div className="flex h-16 mt-6 shrink-0 items-center">
        <img className="h-14 w-auto" src={logo} alt="Inchiriaza Mos Craciun" />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>{renderNavigation()}</li>
          <div className="text-xs text-gray-400 text-center">
            Versiune: {version}
          </div>
        </ul>
      </nav>
    </div>
  );

  return (
    <>
      <Transition.Root show={sidebarOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={React.Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={React.Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {renderSidebarContent()}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {renderSidebarContent()}
      </div>
    </>
  );
};

export default Sidebar;

import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, EyeIcon, PencilIcon } from '@heroicons/react/24/outline';
import { Order } from '../../services/orderService'; 
import { formatInTimeZone } from 'date-fns-tz';
import { ro } from 'date-fns/locale';

interface OrderDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  order: Order | null;
}

const OrderDrawer: React.FC<OrderDrawerProps> = ({ open, setOpen, order }) => {
  if (!order) return null;

  const orderLink = `/dashboard/comenzi/${order.character.firstName.toLowerCase()}-${order.character.lastName.toLowerCase()}-${order._id}`;

  const formatDate = (dateString: string, timeZone: string = 'Europe/Bucharest') => {
    return formatInTimeZone(new Date(dateString), timeZone, 'EEEE, d MMM yyyy', { locale: ro });
  };

  const formatTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };  

  const calculateExtraModelsFee = () => {
    return order.extraCharacters?.reduce((total, extra) => total + extra.modelFee, 0) || 0;
  };

  const renderField = (label: string, value: string | undefined | null, type: 'text' | 'phone' | 'email' = 'text') => {
    const displayValue = value || <span className="text-red-500">Nespecificat</span>;
    switch (type) {
      case 'phone':
        return <p><span className="font-medium">{label}:</span> {value ? <a href={`tel:${value}`} className="text-indigo-600">{value}</a> : displayValue}</p>;
      case 'email':
        return <p><span className="font-medium">{label}:</span> {value ? <a href={`mailto:${value}`} className="text-indigo-600">{value}</a> : displayValue}</p>;
      default:
        return <p><span className="font-medium">{label}:</span> {displayValue}</p>;
    }
  };

  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 desktop-margin flex max-w-full pl-10">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full desktop-border flex-col overflow-y-auto bg-white shadow-xl">
                    <div className="sticky top-0 z-10 bg-white px-4 sm:px-6 py-6 border-b border-gray-200">
                      <div className="flex items-start justify-between">
                        <div>
                          <Dialog.Title className="text-xl font-bold leading-6 text-gray-900">
                            Detalii Comandă
                          </Dialog.Title>
                          <p className="mt-1 text-sm text-gray-500">
                            Aceasta este o previzualizare a comenzii. Pentru a vedea documentele, te rugăm să accesezi pagina comenzii.
                          </p>
                        </div>
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 sm:px-6 pb-6">
                      <div className="mt-6 space-y-3">
                        <a
                          href={orderLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block w-full text-center px-4 py-2 border text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <EyeIcon className="inline-block mr-2 h-5 w-5" aria-hidden="true" />
                          Vezi comanda
                        </a>
                        <button
                          disabled
                          className="block w-full text-center px-4 py-2 border text-sm font-medium rounded-md shadow-sm text-gray-500 bg-gray-200 cursor-not-allowed"
                        >
                          <PencilIcon className="inline-block mr-2 h-5 w-5" aria-hidden="true" />
                          Editează comanda (Indisponibil momentan)
                        </button>

                        {/* <section>
                          <h3 className="text-lg font-semibold text-gray-900 mb-3">Informații Client</h3>
                          <div className="bg-gray-50 rounded-lg p-4 space-y-2 shadow-sm border border-gray-300">
                            {renderField("Nume", order.client.name)}
                            {renderField("Telefon", order.client.phone, "phone")}
                            {renderField("Email", order.client.email, "email")}
                          </div>
                        </section> */}

                        <section>
                          <h3 className="text-lg font-semibold text-gray-900 mb-3">Mențiuni Client</h3>
                          <div className="bg-gray-50 rounded-lg p-4 shadow-sm border border-gray-300">
                            <p className="text-gray-700 italic">
                              {order.client.mentiuni || <span className="text-red-500">Nespecificat</span>}
                            </p>
                          </div>
                        </section>

                        <section>
                          <h3 className="text-lg font-semibold text-gray-900 mb-3">Detalii Comandă</h3>
                          <div className="bg-gray-50 rounded-lg p-4 space-y-2 shadow-sm border border-gray-300">
                            <p>
                              <span className="font-medium">Data și Ora:</span>{' '}
                              <span className="text-green-600 font-semibold">
                                {formatDate(order.period.startDate)}, {formatTime(order.period.startTime)} - {formatTime(order.period.endTime)}
                              </span>
                            </p>
                            {renderField("Adresă", order.address)}
                            {renderField("Zonă", order.zone)}
                            <p>
                              <span className="font-medium">Status:</span>{' '}
                              <span className={`font-semibold ${
                                order.status === 'active' ? 'text-green-600' :
                                order.status === 'completed' ? 'text-blue-600' :
                                'text-red-600'
                              }`}>
                                {order.status}
                              </span>
                            </p>
                          </div>
                        </section>

                        <section>
                          <h3 className="text-lg font-semibold text-gray-900 mb-3">Informații Model</h3>
                          <div className="bg-gray-50 rounded-lg p-4 space-y-2 shadow-sm border border-gray-300">
                            {renderField("Nume Model", `${order.character.firstName} ${order.character.lastName}`)}
                            {renderField("Tip Character", order.character.characterType)}
                          </div>
                        </section>

                        {order.extraCharacters && order.extraCharacters.length > 0 && (
                          <section>
                            <h3 className="text-lg font-semibold text-gray-900 mb-3">Modele Extra</h3>
                            <div className="bg-gray-50 rounded-lg p-4 space-y-2 shadow-sm border border-gray-300">
                              {order.extraCharacters.map((extra, index) => (
                                <p key={index}>
                                  <span className="text-indigo-600 font-semibold">{extra.character.firstName} {extra.character.lastName}</span>{' '}
                                  - {extra.character.characterType} (<span className="text-green-600 font-semibold">{extra.modelFee} RON</span>)
                                </p>
                              ))}
                            </div>
                          </section>
                        )}

                        {order.driver && (
                          <section>
                            <h3 className="text-lg font-semibold text-gray-900 mb-3">Șofer</h3>
                            <div className="bg-gray-50 rounded-lg p-4 space-y-2 shadow-sm border border-gray-300">
                              {renderField("Nume Șofer", `${order.driver.firstName} ${order.driver.lastName}`)}
                              {renderField("Telefon", order.driver.phone, "phone")}
                            </div>
                          </section>
                        )}

                        <section>
                          <h3 className="text-lg font-semibold text-gray-900 mb-3">Detalii Plată</h3>
                          <div className="bg-gray-50 rounded-lg p-4 space-y-2 shadow-sm border border-gray-300">
                            <p>
                              <span className="font-medium">Total:</span>{' '}
                              <span className="text-green-600 font-bold text-lg">{order.billingInfo.totalAmount} RON</span>
                            </p>
                            {renderField("Tarif Model Principal", `${order.billingInfo.modelFee} RON`)}
                            {renderField("Tarif Modele Extra", `${calculateExtraModelsFee()} RON`)}
                            {renderField("Tarif Șofer", `${order.billingInfo.driverFee} RON`)}
                          </div>
                        </section>

                        {order.notes && (
                          <section>
                            <h3 className="text-lg font-semibold text-gray-900 mb-3">Note</h3>
                            <div className="bg-gray-50 rounded-lg p-4 shadow-sm border border-gray-300">
                              <p className="text-gray-700 italic">{order.notes}</p>
                            </div>
                          </section>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default OrderDrawer;
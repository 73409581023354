import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './public/pages/Home';
import Login from './public/pages/Login';
import Dashboard from './admin/pages/Dashboard';
import PrivateRoute from './shared/PrivateRoute';
import { NotificationProvider } from './admin/components/Notification';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  return (
    <NotificationProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </NotificationProvider>
  );
}

export default App;
import React from 'react';

const UnderConstruction: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-900 to-blue-700 flex flex-col justify-center items-center p-4 text-white">
      <div className="max-w-2xl w-full bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-xl p-8 text-center">
        <h1 className="text-4xl font-bold mb-4">Site-ul este în construcție</h1>
        <p className="text-xl mb-8">Revenim în curând cu o experiență magică de Crăciun!</p>
        <div className="flex justify-center space-x-4">
          {['❄️', '🎅', '🎄', '🎁'].map((emoji, index) => (
            <span key={index} className="text-3xl animate-bounce" style={{animationDelay: `${index * 0.2}s`}}>
              {emoji}
            </span>
          ))}
        </div>
      </div>
      <footer className="mt-8 text-sm opacity-70">
        © 2024 Inchiriaza Mos Craciun. Toate drepturile rezervate. v0.9 - ssl  ( dev )
      </footer>
    </div>
  );
};

export default UnderConstruction;